.markdown-page {
  display: flex;
}

.markdown-page nav {
  background-color: azure;
  min-height: 101vh;
  min-width: 280px;
  padding-left: 1em;
}

.markdown-page nav button {
  border: none;
  background: none;
  text-decoration: underline;
  color: "blue";
}

.markdown-page nav ul.tree,
.markdown-page nav ul.tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.markdown-page nav ul.tree ul {
  margin-left: 10px;
}

.markdown-page nav ul.tree li {
  margin: 0;
  padding: 0 7px;
  line-height: 20px;
  color: #369;
  font-weight: bold;
  border-left: 1px solid rgb(100, 100, 100);
}

.markdown-page nav ul.tree li:last-child {
  border-left: none;
}

.markdown-page nav ul.tree li:before {
  position: relative;
  top: -0.3em;
  height: 1em;
  width: 12px;
  color: white;
  border-bottom: 1px solid rgb(100, 100, 100);
  content: "";
  display: inline-block;
  left: -7px;
}

.markdown-page nav ul.tree li:last-child:before {
  border-left: 1px solid rgb(100, 100, 100);
}

.markdown-page .doc-wrapper {
  min-width: 400px;
  max-width: 600px;
  padding: 0 2em;
}

.markdown-page .doc-wrapper h2 {
  margin-top: 2em;
}