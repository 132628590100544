* {
  box-sizing: border-box;
}

.app {
  position: relative;
}

.error {
  max-width: 660px;
  margin: 0 auto;
  line-height: 1.8;
}

.old-lysa-docs button {
  min-width: 100px;
  padding: 0 16px;
  height: 46px;
  line-height: 46px;
  background-color: white;
  border: 1px solid #83b6e2;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.old-lysa-docs button.active {
  background-image: url("/pattern.svg");
  background-repeat: repeat;
}

.old-lysa-docs main {
  display: flex;
}

.old-lysa-docs main aside {
  flex: 0 0 240px;
}

.old-lysa-docs main > div {
  flex: 1 0 200px;
}

.old-lysa-docs nav ul li {
  display: inline-block;
  margin: 20px 0 20px 20px;
}

.old-lysa-docs nav ul,
.old-lysa-docs aside ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.old-lysa-docs aside li {
  margin: 10px 0 0 20px;
}

.old-lysa-docs aside button {
  width: 220px;
}

.old-lysa-docs aside h4 {
  margin: 50px 0 0 20px;
  color: #666;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
}
