.lysa-doc {
  margin: 0 auto;
  max-width: 660px;
  line-height: 1.8;
}

.lysa-doc section {
  margin: 70px 0 0 0;
  padding: 0;
}

.lysa-doc h4 {
  margin-top: 50px;
}

.lysa-doc .doc-number {
  margin: 0 5px 0 0;
}

.lysa-doc .highlighted h2:first-child,
.lysa-doc .highlighted h3:first-child,
.lysa-doc .highlighted h4:first-child {
  margin-top: 0px;
}

.lysa-doc h2 + h4,
.lysa-doc h3 + h4 {
  margin-top: 26px;
}

.lysa-doc address {
  font-style: normal;
}

.lysa-doc .highlighted {
  background-color: #f2f5f8;
  padding: 30px 10px;
}

.lysa-doc .ingress-note {
  font-style: italic;
}

.lysa-doc .ingress-note i {
  font-style: normal;
}

@media (min-width: 480px) {
  .lysa-doc .highlighted {
    padding: 30px 16px;
  }
}

@media (min-width: 768px) {
  .lysa-doc .highlighted {
    padding: 40px 25px;
  }
}
